<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div>
    <v-row class="mb-3">
      <template v-for="(value, level) in alerts">
        <v-col :key="`alert_${level}`" cols="12" md="6" lg="3">
          <v-alert border="left" :color="alertTypeToColor(level)" dark class="py-2">
            <template v-slot:prepend>
              <div class="d-inline-block white--text text-h4 px-3">{{ value }}</div>
            </template>
            {{ alertText(level) }}
          </v-alert>
        </v-col>
      </template>

      <v-col cols="12" md="6" lg="3">
        <v-alert border="left" color="grey" dark class="py-2">
          <template v-slot:prepend>
            <div class="d-inline-block white--text text-h4 px-3">{{ unansweredCounter }}</div>
          </template>
          {{ unansweredCounter > 1 ? 'Questionnaires non répondus' : 'Questionnaire non répondu' }}
        </v-alert>
      </v-col>
    </v-row>
    <v-card class="elevation-x py-3 mb-3">
      <v-card-title class="pt-0 font-alt">Questionnaires en attente d'acquittement</v-card-title>
      <v-card-subtitle class="font-weight-light text-caption font-alt">
        Liste des questionnaires en attente d'acquittement
      </v-card-subtitle>
      <v-divider />
      <surveys-list ref="pending-list"
                    answered
                    :alert-counter-low.sync="alerts.low"
                    :alert-counter-medium.sync="alerts.medium"
                    :alert-counter-high.sync="alerts.high"
                    @select-item="selectSurveyItem"
      />
    </v-card>

    <v-card class="elevation-x py-3">
      <v-card-title class="pt-0 font-alt">Questionnaires non répondus</v-card-title>
      <v-card-subtitle class="font-weight-light text-caption font-alt">
        Liste des questionnaires non répondus
      </v-card-subtitle>
      <v-divider />
      <surveys-list unanswered :total.sync="unansweredCounter" @select-item="selectSurveyItem" />
    </v-card>

    <survey-detail v-if="showDetailDialog" :survey-status="surveyStatus"
                   :survey-response="surveyResponse" :survey-comment="surveyComment"
                   :patient-selected="patientSelected" :stay-survey-iri="staySurveyIri"
                   :stay-survey-id="staySurveyId"
                   @acknowledge="acknowledge"
                   @close="closeDetailDialog"
    />
  </div>
</template>

<script>
  import SurveysList from '@/modules/professionals/components/survey/SurveysList'
  import SurveyDetail from '@/modules/surveys/components/responses/SurveyDetailsDialog'
  import * as surveys from '@/modules/surveys/api'
  import { getStats } from '@/modules/establishments/api/establishments'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'SurveysPending',
    components: {SurveysList, SurveyDetail},
    data() {
      return {
        currentEstablishment: [],
        surveyResponse: [],
        patientSelected: [],
        surveyComment: {},
        surveyStatus: {},
        staySurveyId: {},
        staySurveyIri: {},
        unansweredCounter: 0,
        alerts: {
          low: 0,
          medium: 0,
          high: 0
        },
        showDetailDialog: false
      }
    },
    computed: {
      ...mapState('establishments', ['allEstablishments'])
    },
    async created() {
      await this.getEstablishments()
      await this.loadStats()
    },
    methods: {
      ...mapActions('establishments', ['getEstablishments']),
      async loadStats() {
        await getStats(this.allEstablishments[0].id).then((response) => {
          this.alerts = response.data.alerts
        })
      },
      async selectSurveyItem(item) {
        if (item.surveyResponseStatus === 'complete' || item.surveyResponseStatus === 'acknowledged') {
          this.patientSelected = item.stay.patientIdentity
          this.surveyComment = item.surveyResponseAcknowledgmentComment
          this.surveyStatus = item.surveyResponseStatus
          this.staySurveyIri = item['@id']
          this.staySurveyId = item.id
          try {
            this.surveyResponse = (await surveys.surveyResponses.get(item.surveyResponseId)).data
          } catch (error) {
            this.$root.$emit('error', error)
          }
          this.showDetailDialog = true
        } else {
          await this.$router.push({name: 'survey-card', params: {id: item.id, item}})
        }
      },
      closeDetailDialog() {
        this.showDetailDialog = false
      },
      acknowledge(staySurveyId) {
        this.$refs['pending-list'].removeItem(staySurveyId)
        this.loadStats()
      },
      alertTypeToColor(type) {
        switch (type) {
          case 'low':
            return 'orange lighten-4'
          case 'medium':
            return 'orange darken-1'
          case 'high':
            return 'red lighten-2'
        }
      },
      alertText(type) {
        switch (type) {
          case 'low':
            return 'Questionnaires en alerte faible'
          case 'medium':
            return 'Questionnaires en alerte moyenne'
          case 'high':
            return 'Questionnaires en alerte importante'
        }
      }
    }
  }
</script>
